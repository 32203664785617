const handleCopy = (text, selector, setState) => {
  const textarea = document.createElement('textarea');
  textarea.value = text;

  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);

  if (selector && setState) {
    setState({
      [selector]: 'Copied',
    });
    setTimeout(() => setState({ [selector]: '' }), 1000);
  }
};

export default handleCopy;