import React from 'react';
import Select from 'react-select';

const CountrySelect = ({ onChange, setSelectedCountry }) => {
  const handleCountryChange = (selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setSelectedCountry(selectedOption.value);
    }
  };

  return (
    <Select
      className=""
      name="select element"
      placeholder="I am a resident or citizen of ..."
      onChange={handleCountryChange}
      options={[
        { value: 'USA', label: 'United States' },
        { value: 'Non-USA', label: 'Other' }
      ]}
      styles={{
        indicatorSeparator: () => ({
          display: 'none',
        }),
        menu: (provided) => ({
          ...provided,
          marginTop: 0,
        }),
        container: (provided) => ({
          ...provided,
          width: '100%',
        }),
        control: (provided) => ({
          ...provided,
          border: '1px solid #E6E6E6',
          borderRadius: '8px',
          minHeight: '2.9375rem',
          cursor: 'pointer',
          boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.03), 0px 3px 6px 0px rgba(0, 0, 0, 0.02)',
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? 'white' : '#30313D',
        }),
      }}
    />
  );
};

export default CountrySelect;