import React, { useEffect, useRef, useState } from 'react';
import './PaymentDataRender.css'
import '../commonStyles/commonStyles.css'
import Timer from '../../assets/Timer.svg'
import CopyPaste from '../../assets/CopyPaste.svg';
import DepositIcon1 from '../../assets/DepositIcon1.svg';
import DepositIcon2 from '../../assets/DepositIcon2.svg';
import DepositIcon3 from '../../assets/DepositIcon3.png';
import Cashback from '../../assets/Cashback.svg';
import { QRCodeSVG } from 'qrcode.react';
import { fetchPaymentDetails } from '../paymentForms/PaymentHandler';
// import AllDone from '../alldone/AllDone';
import PaymentProcessing from '../paymentProcessing/PaymentProcessing';
import handleCopy from '../../utils/handleCopy';

import { ethers, utils } from "ethers";
import { ExternalProvider } from '@ethersproject/providers';

const provider = window.ethereum
  ? new ethers.providers.Web3Provider(window.ethereum, "any")
  : null && console.error("Web3 provider is not available.");

async function sendCrypto(selectedCryptoData, paymentData, setErrorMessage) {
  if(paymentData.pay_currency === "eth"){
    sendEth(selectedCryptoData, paymentData, setErrorMessage);
  }else {
    sendToken(selectedCryptoData, paymentData, setErrorMessage)
  }
}

async function sendToken(selectedCryptoData, paymentData, setErrorMessage) {
  try {
    const to = paymentData.pay_address;
    const tokenNowPay = paymentData.pay_currency;

    const tokenInfo = selectedCryptoData.find(coin => coin.code.toUpperCase() === tokenNowPay.toUpperCase());

    if (!tokenInfo) {
      console.error('Token not found');
      return;
    }

    const tokenAddress = tokenInfo.smart_contract;
    const decimals = tokenInfo.precision;

    console.log(paymentData);
    console.log(paymentData.pay_amount);
    console.log(tokenInfo.precision);
    const amount = ethers.utils.parseUnits(paymentData.pay_amount.toString(), tokenInfo.precision);
    const signer = provider.getSigner();
    const erc20abi = ["function transfer(address to, uint amount)"];
    const erc20 = new ethers.Contract(tokenAddress, erc20abi, provider);
    const dataPayload = await erc20.populateTransaction.transfer(ethers.utils.getAddress(to), amount);
    const txExecuted = await signer.sendTransaction(dataPayload); 
    setErrorMessage('');

  } catch (error) {
    console.error('Transaction Error:', error.message);
    if (error.code === 'ACTION_REJECTED') {
      setErrorMessage('User rejected the transaction');
    } else if(error.error && error.error.message && error.error.message.includes("transfer amount exceeds balance")){
      setErrorMessage(`Transfer amount exceeds ${paymentData.pay_currency.toUpperCase()} balance`);
    } else if(error && error.message && error.message.includes("transfer amount exceeds balance")){
      setErrorMessage(`Transfer amount exceeds ${paymentData.pay_currency.toUpperCase()} balance`);
    }else{
      setErrorMessage('An error occurred. Please try again');
    }
  }
}

async function sendEth(selectedCryptoData, paymentData, setErrorMessage) {
  try {
    const to = paymentData.pay_address;
    const amount = ethers.utils.parseEther(paymentData.pay_amount.toString());
    const signer = provider.getSigner();

    const tx = {
      to: ethers.utils.getAddress(to),
      value: amount,
    };

    const txExecuted = await signer.sendTransaction(tx);
    setErrorMessage('');
  } catch (error) {
    console.error('Transaction Error:', error);
    if (error.code === 'ACTION_REJECTED') {
      setErrorMessage('User rejected the transaction');
    } else if (error.error && error.error.message && error.error.message.includes("insufficient funds for gas")) {
      setErrorMessage(`Transfer amount exceeds ${paymentData.pay_currency.toUpperCase()} balance`);
    } else {
      setErrorMessage('An error occurred. Please try again');
    }
  }
}

function PaymentDataRender(props) {
  const {
    setActualState,
    paymentData,
    planDetails,
    estimatedAmount,
    currencyticker,
    cashbackAmount,
    selectedCryptoData
  } = props

  const [copiedText, setCopiedText] = useState({ first:'', second:'' });  
  const [timer, setTimer] = useState(null); 
  const [processing, setProcessing] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [userWallet, conectedWallet] = useState('');
  const [userNetwork, setUserNetwork] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [tokenNetwork, setTokenNetwork] = useState('');
  const [isCorrectNetwork, setIsCorrectNetwork] = useState(false);
  
  const connectWallet = async(event) => {
    try{
      const accounts = await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      const network  = await provider.getNetwork();
      console.log("Account:", address);
      setIsConnected(true);
      conectedWallet(address);
      setUserNetwork(network.chainId);
    }catch(error){
      console.log(error);
    }
  }

  useEffect(() => {
    const handleAccountsChanged = (accounts) => {
      if (accounts.length === 0) {
        console.log("Metamask has been disconnected.");
        setIsConnected(false);
        conectedWallet('');
        setErrorMessage('');
      } else {
        console.log("Metamask account has changed:", accounts[0]);
        setIsConnected(true);
        conectedWallet(accounts[0]);
      }
    };

    const handleChainChanged = (chainId) => {
        console.log("Chain changed to", chainId);
        setUserNetwork(chainId);
    };

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('chainChanged', handleChainChanged);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      }
    };
  }, []);

  useEffect(() => {
    const tokenInfo = selectedCryptoData.find(coin => coin.code.toUpperCase() === paymentData.pay_currency.toUpperCase());
    console.log("tokenInfo.evm_chain_id", tokenInfo.evm_chain_id);
    console.log("userNetwork", userNetwork);
    if (parseInt(tokenInfo.evm_chain_id) !== parseInt(userNetwork) && userNetwork !== '') {
      setErrorMessage(`Please switch to the correct network: ${tokenInfo.network.toUpperCase()}`);
      setIsCorrectNetwork(false);
      return;
    } else {
      setErrorMessage('');
      setIsCorrectNetwork(true);
    }
  }, [userNetwork, paymentData.pay_currency, selectedCryptoData]);

  useEffect(() => {
    const checkWalletConnected = async () => {
      try {
        const accounts = await provider.listAccounts();
        if (accounts.length > 0) {
          const network  = await provider.getNetwork();
          const signer = provider.getSigner();
          const address = await signer.getAddress();
          console.log("Wallet already isConnected:", address);
          setIsConnected(true);
          conectedWallet(address);
          setUserNetwork(network.chainId);
        } else {
          console.log("No wallet conected");
        }
      } catch (error) {
        console.error("Error", error);
      }
    };
    checkWalletConnected();
  }, []);

  useEffect(() => {
    const createdAt = new Date(paymentData.created_at).getTime();
    const now = new Date().getTime();
    const twentyMinutes = 20 * 60 * 1000; // 20 minutos en milisegundos
    const timePassed = now - createdAt;
    const timeLeft = twentyMinutes - timePassed;
    const timeLeftInSeconds = Math.max(Math.floor(timeLeft / 1000), 0);
    setTimer(timeLeftInSeconds);

    const timerIntervalID = timeLeftInSeconds > 0 ? setInterval(() => {
      setTimer((prevTimer) => {
        const newTimer = prevTimer - 1;
        if (newTimer <= 0) {
          clearInterval(timerIntervalID);
          //setActualState('paymentProcessing'); 
          return 0;
        }
        return newTimer;
      });
    }, 1000) : null;

    let fetchCount = 0;
    const fetchIntervalID = setInterval(() => {
      if (fetchCount % 15 === 0) { 

        fetchPaymentDetails(paymentData.payment_id, setActualState);
      }
      fetchCount++;
    }, 1000);

    return () => {
      if (timerIntervalID !== null) clearInterval(timerIntervalID);
      clearInterval(fetchIntervalID);
    };
  }, [paymentData, setActualState]);
  
  const payAddressRef = useRef(null);
  const amountInCryptoRef = useRef(null);
  
  const handleCopyClick = (textToCopy, selector) => {
    handleCopy(textToCopy, selector, setCopiedText);
  };

  //console.log('paymentData expiration_estimate_date', paymentData.expiration_estimate_date)

  const formatTime = (seconds) => {
    if (seconds <= 0) {
      return "Expired";
    }
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const timerClass = timer <= 0 ? "timer-box alert-text" : "timer-box";
  const addressQr = `${paymentData.network}:${paymentData.pay_address}?amount=${paymentData.pay_amount}`;

  return (            
  <div className='half-bottom-height'>
    <div className='half-bottom-content-container'>
      {!processing ? (
      <div className='half-bottom-content-box'>
      <div className='QRCode-container'>
        <div className='QRCode-box'>
          <div className='send-deposit-and-timer-box'>
            <span className="amount-title">Send deposit</span>
            <div className={timerClass}>
              <img src={Timer} className='timer' alt='Timer' />
              <span className='timer-time'>{formatTime(timer)}</span>
            </div>
          </div>
          <div className='flex-qr'>
            {!isConnected && 
              <QRCodeSVG value={addressQr} />
            }
            <div className='amount-address-box'>
              <div className='amount-box'>
                <div className='amount-text-amount-usd-box'>
                  <span className='amount-text'>Amount:</span>
                  <span className='amount'>{planDetails.amount}</span>
                </div>
                <div className='amount-in-crypto-box'>
                  <span 
                    className="amount-in-crypto"
                    ref={amountInCryptoRef}
                    onClick={() => handleCopyClick(`${estimatedAmount} ${currencyticker && currencyticker.toUpperCase()}`, 'first')}
                  >
                    {paymentData.pay_amount && parseFloat(paymentData.pay_amount).toFixed(4)}
                    &nbsp;
                    {currencyticker && currencyticker.toUpperCase()}
                  </span>
                  {copiedText.first 
                    ? 
                    <div className="copied-text-box">
                      <span className='copied-text'>
                        {copiedText.first} 
                      </span>
                    </div>
                    : <img 
                      src={CopyPaste} 
                      className='cursor-pointer' 
                      alt="Copy Paste" 
                      onClick={() => handleCopyClick(`${estimatedAmount} ${currencyticker && currencyticker.toUpperCase()}`,'first')}
                    />
                  }
                </div>
                <span className='alert-text-chain'>Send only from {paymentData.network.toUpperCase()} network</span>
              </div>
              <div className='address-box'>
                <div className='address-text-address'>
                <div className='address-tag'>
                  <span className='address-text'>Address: </span>
                </div>
                  <span className='address' ref={payAddressRef}>
                    {paymentData.pay_address}
                  </span>
                </div>
                {copiedText.second 
                  ?
                  <div className="copied-text-box">
                    <span className='copied-text'>
                      {copiedText.second} 
                    </span>
                  </div>
                  : <img 
                      src={CopyPaste} 
                      className='copy-paste-img cursor-pointer' 
                      alt="Copy Paste" 
                      onClick={() => handleCopyClick(paymentData.pay_address, 'second')}
                    />
                  }
              </div>
            </div>
          </div>
        </div>
        {cashbackAmount && (
          <div className='your-cashback-box'>
            <div className='your-cashback-icon-text'>
              <img src={Cashback} className='your-cashback' alt="Your cashback" />
              <span className='your-cashback-txt'>Your cashback</span>
            </div>
            <span className='cashback-box-amount'>{cashbackAmount && parseFloat(cashbackAmount).toFixed(2)} USDC</span>
          </div>
        )}

        {errorMessage && (
          <div className="alert-text rejected">
            {errorMessage}
          </div>
        )}

        <div className='deposit-options-box'>
          {!isConnected && 
            <span className='deposit-options-text'>More payment options</span>
          }
          <div className='deposit-icons-box'>
              <div className='deposit-icons-frame metamask-frame' onClick={connectWallet}>
                <img src={DepositIcon2} className='deposit-icons cursor-pointer' alt="deposit icons metamask" />
                <span className={`connection-status ${isConnected ? 'connected' : 'disconnected'}`}></span>
              </div>  
              { userWallet &&
                <button
                  disabled={!(isCorrectNetwork)}
                  className='pay-button'
                  onClick={() => sendCrypto(selectedCryptoData, paymentData, setErrorMessage)}
                >
                  <div className='pay-button-text-icon'>
                    {/*
                    <span>Deposit {Number(paymentData.pay_amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 4 })} {currencyticker && currencyticker.toUpperCase()}</span>
                    */}
                    <span>Pay from your wallet</span>
                  </div>
                </button>
              }
            {/*
            <div className='deposit-icons-frame trustyfy-frame'>
              <img src={DepositIcon3} className='deposit-icons cursor-pointer' alt="deposit icons trustyfy" />
            </div>
            <div className='deposit-icons-frame walletConnect-frame'>
              <img src={DepositIcon1} className='deposit-icons cursor-pointer' alt="deposit icons" />
            </div>
            */}
          </div>
        </div>
        <span className='trustify-terms-text'>By purchasing, you accept Trustify Terms and Conditions</span>
      </div>
      </div>
      ) : 
      <PaymentProcessing
        paymentData={paymentData}
      />
      }
    </div>
  </div>);
}

export default PaymentDataRender;