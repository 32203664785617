const getPlanDetails = () => {
  if (window.location.href.includes('/legacy')) {
    return {
      plan: 'Legacy Plan',
      amount: '$ 497.00',
      amountUSD: 497
    };
  } else if (window.location.href.includes('/pro')) {
    return {
      plan: 'Pro personal account',
      amount: '$ 60.00',
      amountUSD: 60
    };
  } else if (window.location.href.includes('/corporate')) {
    return {
      plan: 'Corporate Pro Account 1 Year',
      amount: '$ 300.00',
      amountUSD: 300
    };
  } else if (window.location.href.includes('/test')) {
    return {
      plan: 'Test Plan',
      amount: '$ 210.00',
      amountUSD: 210
    };
  } else {
    return {
      plan: 'Corporate Pro Account 1 Year',
      amount: '$ 300.00',
      amountUSD: 300
    };
  }
};

export default getPlanDetails;