import React, {useState} from 'react';
import './AllDone.css'
import './AllDoneCommon.css'
import '../commonStyles/commonStyles.css'
import DoneSVG from '../../assets/Done.svg';
import CopyPaste from '../../assets/CopyPaste.svg';
import handleCopy from '../../utils/handleCopy';

const AllDone = ({paymentData, paymentDataCard}) => {
  // const handleAlrightButtonClick = () => {
  //   window.location.href = 'https://app.trustyfy.com';
  // };

  const [copiedText, setCopiedText] = useState({ copy: '' });

  console.log("paymentData", paymentData);
  console.log("paymentDataCard", paymentDataCard);

  const payId = paymentData ? paymentData.payment_id : paymentDataCard.paymentIdMongo;
  const payAmountUsd = paymentData ? paymentData.price_amount : paymentDataCard.amountUSD;
  const payCurrency = paymentData ? paymentData.price_currency.toUpperCase() : " USD";
  
  return (
    <div className='half-bottom-height'>
      <div className='half-bottom-content-container'>
        <div className="content-box">
          <div className='all-done-box-inside'>
            <div className='done-icon-img-box'>
              <div className='done-icon-img-small-box'>
                <img src={DoneSVG} alt="Done img" className='done-icon' />
              </div>
            </div>
            <h1 className='all-done-header'>Paid <br/>successfully</h1>
            <span className='alert-text'><strong>Add support@trustyfy.com to your contacts list</strong> to avoid losing the seller’s instruction to spam.</span>
              <span className='text-all-done'>{payAmountUsd}{payCurrency} sent to the seller. If you don’t receive instructions to your email within 24 hours, check your spam folder or contract support@trustyfy.com.</span>
            <div className='payment-id-box'>
              <span className='text-12px'>Payment ID</span>
                <span className='processing-payment-id'>{payId}</span>
              {copiedText.copy 
                ? 
                  <span className='copied-text'>
                    {copiedText.copy} 
                  </span>
                : <img 
                    src={CopyPaste} 
                    className='cursor-pointer' 
                    alt="Copy Paste" 
                    onClick={() => handleCopy(payId, 'copy', setCopiedText)}
                  />
              }
            </div>
          </div>
        </div>
      </div>
    </div>  
  );
};

export default AllDone;