import React from 'react';
import Lottie from 'lottie-react';
import loadingSVG from './loadingSVG.json';

const LoadingImage = () => {
  const wrapperStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  };

  const divStyles = {
    maxWidth: '200px',
    height: 'auto',
  };

  return (
    <div style={wrapperStyles}>
      <div style={divStyles}>
        <Lottie animationData={loadingSVG} loop />
      </div>
    </div>
  );
};

export default LoadingImage;