import React from 'react';
import './AllDone.css'
import './Failed.css'
import './AllDoneCommon.css'
import '../commonStyles/commonStyles.css'
import FailedSVG from '../../assets/Failed.svg';

const Failed = () => {
  const handleTryAgainButtonClick = () => {
    window.location.href = '/';
  };

  return (
    <div className='half-bottom-height'>
      <div className='half-bottom-content-container'>
        <div className='failed-box'>
          <div className='failed-icon-img-box'>
            <div className='failed-icon-img-small-box'>
              <img src={FailedSVG} alt="Failed img" className='failed-icon' />
            </div>
          </div>
          <h1 className='failed-header'>Payment failed</h1>
          <span className='alert-text'>If this error continues, please <strong>contact support at support@trustyfy.com."</strong></span>
          <span className='all-done-text'>Something went wrong. What would you like to do?</span>
          <button className='alright-button' onClick={handleTryAgainButtonClick}>Try again</button>
        </div>
      </div>
    </div>  
  );
};

export default Failed;