import React from 'react';
import './ErrorMessages.css'

const RenderErrorMessages = (props) => {
  const { isIntroducerValid, showIntroducerError, showEmailError, email } = props

  return (
    <>
      {!isIntroducerValid && showIntroducerError && (
        <div className="error-message-container-introducer">
          Must be 6 characters.
          <div className="arrow-left"></div>
        </div>
      )}
      {showEmailError && (
        <div className="error-message-container-email">
          {email.trim() === '' ? 'Please, enter your email.' : "Please, enter a valid email." || 'Please, enter a valid email.'}
          <div className="arrow-right"></div>
        </div>
      )}
    </>
  );
};

export default RenderErrorMessages;