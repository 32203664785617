import axios from 'axios';

export const fetchPaymentDetails = async (pid, setActualState) => {
  try {
    console.log("call to payment_status")
    const response = await axios.get(`https://external-payment-backend.onrender.com/payment-status?pid=${pid}`);
    console.log(response)

    if(response.data && response.data.payment_status === "confirming"){
      setActualState("paymentProcessing");
    }

    if(response.data && response.data.payment_status === "confirmed"){
      setActualState("allDone");
    }

  } catch (error) {
    console.error('Error while getting payment data', error);
  }
}

export const fetchOrCreatePayment = async (planDetails, email, resident, currency, introducer, cashback) => {
  try {
    const response = await axios.get(`https://external-payment-backend.onrender.com/pending-by-mail?email=${email}`);
    console.log(response);
    if (response.data && response.data.payment_id) {
      console.log("entra que el user tiene payment ya");
      return response.data;
    } else {
      console.log('Solicitud de creación de pago:');
      console.log('Email:', email);
      console.log('Residente:', resident);
      console.log('Moneda:', currency);
      console.log('Introducer:', introducer);
      console.log('Cashback:', cashback);
      const newPaymentResponse = await axios.post('https://external-payment-backend.onrender.com/nowpay-intent-integrated', {
        amountUSD: planDetails.amountUSD, 
        email: email, 
        introducer: introducer || "", 
        isFromUS: resident === 'US' ? "true" : "false", 
        service: planDetails.plan, 
        token: currency, 
        cashback: cashback ?? 0 
      });
      return newPaymentResponse.data;
    }
  } catch (error) {
    console.error('Error fetching or creating payment', error);
    throw error; 
  }
};