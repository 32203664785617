import React, { useState } from 'react';
import {ReactComponent as Cashback} from '../../assets/Cashback.svg';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import './CardPaymentForm.css'
import '../commonStyles/commonStyles.css'
import {ReactComponent as CardPay2} from '../../assets/CardPay2.svg'
import {ReactComponent as AmExCard} from '../../assets/AmExCard.svg'
import {ReactComponent as DiscoverCard} from '../../assets/DiscoverCard.svg'
import {ReactComponent as MasterCard} from '../../assets/MasterCard.svg'
import {ReactComponent as VisaCard} from '../../assets/VisaCard.svg'
import {ReactComponent as AmExCardNotFilled} from '../../assets/AmExCardNotFilled.svg'
import {ReactComponent as DiscoverCardNotFilled} from '../../assets/DiscoverCardNotFilled.svg'
import {ReactComponent as MasterCardNotFilled} from '../../assets/MasterCardNotFilled.svg'
import {ReactComponent as VisaCardNotFilled} from '../../assets/VisaCardNotFilled.svg'
import {ReactComponent as Calendar} from '../../assets/Calendar.svg'
import {ReactComponent as Frame} from '../../assets/Frame.svg'
import CountrySelect from '../select/CountrySelect';

function CardPayment(props) {
  const {    
    email,
    introducer,
    isFromUS,
    isEmailValid,
    isIntroducerValid,
    setActualState,
    planDetails,
    setpaymentIdMongo,
    cashbackAmount,
    isPayingInCrypto,
    isPayingSelected,
    selectedCountry,
    setSelectedCountry,
    setPaymentDataCard,
    setPayButtonClicked,
    introducerParam,
  } = props

  const stripe = useStripe();
  const elements = useElements();
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [cardData, setCardData] = useState({cardNumber:false, date: false, cvc: false });
  const [cardDataIsComplete, setCardDataIsComplete] = useState(false);
  const [cardBrand, setCardBrand] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      console.log('Stripe.js has not yet loaded.');
      return;
    }

    setPaymentProcessing(true);
    const cardElement = elements.getElement(CardNumberElement);

    try {
      const reqBody = {
        amountUSD: planDetails.amountUSD,
        email,
        introducer,
        isFromUS: isFromUS ? 'yes': 'no',
        service: planDetails.plan,
        cashback: cashbackAmount
      }

      const response = await fetch('https://external-payment-backend.onrender.com/api/payment-intent-stripe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify(reqBody),
      });

      const data = await response.json();
      console.log('Response from /api/payment-intent:', data);

      setpaymentIdMongo(data.paymentIdMongo);
      console.log("setPaymentDataCard");
      setPaymentDataCard({...data, ...reqBody});
      const clientSecret = data.clientSecret;
      if (!clientSecret) {
        console.log('Client secret not found in the response.');
        setPaymentProcessing(false);
        return;
      }

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (result.error) {
        console.log('Payment error:', result.error.message);
        setActualState("failed");
      } else {
        if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
          console.log('Payment successful!');
          setPayButtonClicked(true);
          setActualState("allDone");
        }
      }
    } catch (error) {
      console.error('Error during card payment processing:', error);
    } finally {
      setPaymentProcessing(false);
    }
  };

 const handleUpdateCardData = (newData) => {
    const actualCardData = newData.cardNumber && newData.date && newData.cvc
    if(actualCardData) {
      setCardDataIsComplete(true);
    } else if(cardDataIsComplete && !actualCardData) {
      setCardDataIsComplete(false);
    }
    setCardData(newData);
  }

  const commonStyle = {
    base: {
      fontSize: '16px',
      color: '#30313D',
      fontFamily: 'sans-serif',
      '::placeholder': {
        color: '#B7B3BE',
        content: 'Card number',
        fontFamily: 'sans-serif',
      },
    },
    invalid: {
      color: '#F95A44',
    },
  };

  const cardNumberStyle = {
    style: { ...commonStyle },
    placeholder: '1234 1234 1234 1234',
  };

  const MMYYStyle = {
    style: { ...commonStyle },
  };

  const CVCStyle = {
    style: { ...commonStyle },
    placeholder: 'CVC',
  };

  return (
  	<>
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>      
        <div className='cardholder-info-box'>
          <div className='input-group-box'>
            <div className="input-group-entire-width">
              <div className='center-content input-entire-width'>
                <div className='cards-box padding-right-0'>
                  <CardPay2 className={`icon`}></CardPay2>
                </div>
                <CardNumberElement 
                  options={cardNumberStyle}
                  className='card-element-text'
                  onChange={(ev) => {
                    handleUpdateCardData({ 
                      ...cardData,
                      cardNumber: ev.complete
                    })
                    setCardBrand(ev.brand);
                  }} 
                />
                {!cardBrand && 
                  <div className='cards-box'>
                    <MasterCard className={`icon-card`}></MasterCard>
                    <VisaCard className={`icon-card`}></VisaCard>
                    <AmExCard className={`icon-card`}></AmExCard>
                    <DiscoverCard className={`icon-card`}></DiscoverCard>
                  </div>
                }
                {cardBrand && 
                  <div className='cards-box'>
                    {cardBrand !== "mastercard" ? <MasterCardNotFilled className={`icon-card`}></MasterCardNotFilled> : <MasterCard className={`icon-card`}></MasterCard>}
                    {cardBrand !== "visa" ? <VisaCardNotFilled className={`icon-card`}></VisaCardNotFilled> : <VisaCard className={`icon-card`}></VisaCard>}
                    {cardBrand !== "amex" ? <AmExCardNotFilled className={`icon-card`}></AmExCardNotFilled> : <AmExCard className={`icon-card`}></AmExCard>}
                    {cardBrand !== "discover" ? <DiscoverCardNotFilled className={`icon-card`}></DiscoverCardNotFilled> : <DiscoverCard className={`icon-card`}></DiscoverCard>}
                  </div>
                }
              </div>
            </div>
            <div className='half-input-box'>
              <div className="input-group-half-width">
                <label className='center-content input-half-width r1'>
                  <div className='cards-box'>
                    <Calendar className={`icon`}></Calendar>
                  </div>
                  <CardExpiryElement 
                    options={MMYYStyle}
                    className='card-element-text'
                    onChange={(ev) => {
                      handleUpdateCardData({ 
                        ...cardData,
                        date: ev.complete
                      })
                    }} 
                  />
                </label>
              </div>
              <div className="input-group-half-width">
                <label className='center-content input-half-width r2'>
                  <CardCvcElement 
                    options={CVCStyle}
                    className='card-element-text'
                    onChange={(ev) => {
                      handleUpdateCardData({ 
                        ...cardData,
                        cvc: ev.complete
                      })
                    }} 
                  />
                  <div className='cards-box'>
                    <Frame className={`icon`}></Frame>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <>
            {isPayingSelected && !isPayingInCrypto &&
              <>
                <CountrySelect setSelectedCountry={setSelectedCountry} />
              </> 
            }
          </>         
          {isIntroducerValid && cashbackAmount &&
            <>
              {(cashbackAmount || introducerParam) && (
                <div className='your-cashback-box'>
                  <div className='your-cashback-icon-text'>
                    <Cashback className='your-cashback'></Cashback>
                    <span className='your-cashback-txt'>Your cashback:</span>
                  </div>
                  <span className='cashback-box-amount'>{cashbackAmount && parseFloat(cashbackAmount).toFixed(2)} USDC</span>
                </div>
              )}   
            </>
          }       
          <button 
            disabled={
              !stripe ||
              paymentProcessing ||
              !(isEmailValid && isIntroducerValid && cardDataIsComplete && selectedCountry)
            } 
            className="pay-button"
          >
            {paymentProcessing ? 'Processing...' : `Pay ${planDetails.amount}`}
          </button>
          {isPayingSelected && !isPayingInCrypto && <p class="trustify-terms-text">By purchasing the plan, you accept Trustyfy Terms.</p>}    
        </div>  
      </form>
    </>
  );
}

export default CardPayment;
