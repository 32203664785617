import React from 'react';
import {ReactComponent as Vector} from '../../assets/Vector.svg';
import './HeaderSibling.css'

const HeaderSibling = (props) => {
  const {
    payButtonClicked,
    shouldDisplayCircle,
    planDetails,
    email,
    handleEmailChange,
    isPlaceholderVisible,
    introducer,
    handleIntroducerChange,
    setIsPlaceholderVisible,
    renderErrorMessages,
    isPayingSelected,
  } = props;

  return ( 
    <div className={`half-top-content-container ${!(payButtonClicked) ? 'allDone' : 'half-top-content-container-payingSelected'}`}>
      {/* {isPayingSelected && isFirstStep && actualState !== 'payment' && (
        <div onClick={handleBackClick} style={{ position: 'absolute', top: '18px', left: '18px', cursor: 'pointer' }}>
        <BackArrow alt="Back" width="22px" height="22px" ></BackArrow>
        </div>
      )} */}
      <div className='half-top-content-box'>
        <span className='header-text'>Trustyfy</span>
        {shouldDisplayCircle && <div className="circle">t</div>}

        <div className='service-year-details'>
          {planDetails.plan === 'Corporate Pro Account 1 Year' ? (
            <>
              <div className='service-year-text corporate-pro-account'>{planDetails.plan.split(' ').slice(0, -2).join(' ')}</div>
              <div className='service-year-text year'>{planDetails.plan.split(' ').slice(-2).join(' ')}</div>
            </>
          ) : (
            <div className='service-year-text'>{planDetails.plan}</div>
            )}
        </div>

        {payButtonClicked === false ?
          <>
            <div className='input-introduced-box'>
              <div className="input-group">
                <input 
                  className='input-max-width' 
                  autoComplete='off' 
                  type="email" 
                  id="email" 
                  placeholder='Your email' 
                  value={email} 
                  onChange={handleEmailChange} />
              </div>
              <div className='introduced-by-box'>
                <label className="introduced-by">
                  <div className='introduced-by-icon-text'>
                    <Vector className={`introduced-by-icon `}></Vector>
                  </div>
                  <input 
                    autoComplete='off' 
                    placeholder={isPlaceholderVisible ? 'Introducer' : ''}
                    className='introduced-by-input' 
                    type="text" 
                    id="introducer" 
                    value={introducer} 
                    onChange={handleIntroducerChange} 
                    onClick={() => setIsPlaceholderVisible(false)}
                    onBlur={() => setIsPlaceholderVisible(true)} 
                    />
                </label>
              </div>
            </div>
            {renderErrorMessages()}
          </>
          :
          <div className='line-mail-box'>
            <div className='buyer-info-box'>
              <span className='buyer-info-txt'>{email}</span>
              {isPayingSelected && introducer && (
                <div className='buyer-info'>
                  <Vector className={`introduced-by-icon selected`}></Vector>
                  <span className='buyer-info-txt'>{introducer}</span>
                </div>
              )}
            </div>
          </div>
        }
      </div>
    </div> 
  )
}

export default HeaderSibling