import React, { useEffect, useState } from 'react';
import './PaymentProcessing.css';
import CopyPaste from '../../assets/CopyPaste.svg';
import '../alldone/AllDone.css'
import '../../App.css'
import '../commonStyles/commonStyles.css'
import handleCopy from '../../utils/handleCopy';
import { fetchPaymentDetails } from '../paymentForms/PaymentHandler';
import { ReactComponent as Loadingstatic } from '../../assets/Loadingstatic.svg';


function PaymentProcessing({ 
  paymentData,
  setActualState
}) {

  useEffect(() => {
    console.log("calling the setInterval")
    const fetchIntervalID = setInterval(() => {
      console.log("inside setInterval")
      fetchPaymentDetails(paymentData.payment_id, setActualState);
    }, 10000); // 10 segundos

    return () => {
      clearInterval(fetchIntervalID);
    };
  }, [paymentData, setActualState]);

  const [copiedText, setCopiedText] = useState({ first:'', second:'' });  

  return (            
    <div className='half-bottom-height'>
      <div className='half-bottom-content-container'>
        <div className='content-box'>
          <Loadingstatic className='loading_static'></Loadingstatic>
          <span className='processing-header'>Payment processing</span>
          <span className='processing-text'>We’ve received your payments of {paymentData.price_amount} {paymentData.price_currency.toUpperCase()}, it is currently being processed. It usually takes under 5 minutes!</span>
          
          <div className='payment-id-box'>
            <span className='text-12px'>Payment ID</span>
            <span className='processing-payment-id'>{paymentData.payment_id}</span>
              {copiedText.copy 
                ? 
                  <span className='copied-text'>
                    {copiedText.copy} 
                  </span>
                : <img 
                  src={CopyPaste} 
                  className='cursor-pointer' 
                  alt="Copy Paste" 
                  onClick={() => handleCopy(paymentData.payment_id, 'copy', setCopiedText)}
                  />
              }
          </div>
        </div>
      </div>
    </div>  
  );
}

export default PaymentProcessing;