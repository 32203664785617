import React, { useState, useEffect, useCallback } from 'react';
import './CryptoPayment.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

function CryptoPayment(props) {
  const {    
    planDetails, 
    selectedCryptoData, 
    setSelectedCryptoCurrency,
    estimatedAmount,
    setEstimatedAmount,
    currencyticker,
    setCurrencyticker
  } = props

  const [selectedCurrency, setSelectedCurrency] = useState(); // Aquí debemos inicializar selectedCurrency con un objeto que tenga la estructura { value: 'USDC', label: 'USDC', logo_url: 'url_de_logo' }

  const fetchEstimatedAmount = useCallback(async (amount, currencyCode) => {
    const response = await fetch(`https://external-payment-backend.onrender.com/get-price?amount=${amount}&currency_to=${currencyCode}`);
    const data = await response.json();

    setEstimatedAmount(data.estimated_amount);
    setCurrencyticker(data.currency_to);
  }, [setEstimatedAmount, setCurrencyticker]);

  useEffect(() => {
    const defaultCurrency = selectedCryptoData.find(currency => currency.code === 'USDC');
    if (defaultCurrency) {
      setSelectedCurrency({
        value: defaultCurrency.code,
        label: <div style={{ display: 'flex', alignItems: 'center' }}><img src={defaultCurrency.logo_url} alt="" style={{ width: 20, marginRight: 10 }} />{defaultCurrency.name}</div>,
        logo_url: defaultCurrency.logo_url
      });
      setSelectedCryptoCurrency(defaultCurrency.code); 
      fetchEstimatedAmount(planDetails.amountUSD, defaultCurrency.code);
    }
  }, [selectedCryptoData, fetchEstimatedAmount, setSelectedCryptoCurrency, planDetails.amountUSD]);

  const animatedComponents = makeAnimated();

  const options = selectedCryptoData.map(data => ({
    value: data.code,
    label: <div style={{ display: 'flex', alignItems: 'center' }}><img src={data.logo_url} alt="" style={{ width: 20, marginRight: 10 }} />{data.name}</div>,
    logo_url: data.logo_url
  }));

  const customSingleValue = ({ data }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src={data.logo_url} alt="" style={{ width: 20, marginRight: 10 }} />
      {data.label}
    </div>
  );

  const handleCurrencyChange = (selectedOption) => {
    console.log(selectedOption)
    if (selectedOption) {
      setSelectedCurrency(selectedOption);
      setSelectedCryptoCurrency(selectedOption.value);
      fetchEstimatedAmount(planDetails.amountUSD, selectedOption.value);
    } else {
      setSelectedCurrency(null);
      setEstimatedAmount('');
      setSelectedCryptoCurrency(null); 
    }
  };

  return (
    <div className='crypto-select-box'>
    <Select
      components={{ SingleValue: customSingleValue, ...animatedComponents }}
      options={options}
      value={selectedCurrency}
      onChange={handleCurrencyChange}
      isSearchable={true}
      isClearable={false}
      styles={{
        indicatorSeparator: () => ({
          display: 'none',
        }),
        menu: (provided) => ({
          ...provided,
          marginTop: 0,
          overflowY: 'hidden', 
        }),
        container: (provided) => ({
          ...provided,
          width: '100%',
        }),
        control: (provided) => ({
          ...provided,
          border: '1px solid #E6E6E6', 
          backround: '#555',
          borderRadius: '8px', 
          minHeight: '2.9375rem', 
          cursor: 'pointer',
          boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.03), 0px 3px 6px 0px rgba(0, 0, 0, 0.02)',
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? 'white' : '#30313D', 
        }),
      }}
    />
    <div className="label">
      <span className="amount-payment-title">Amount to Pay:</span>
      <span className="amount-to-pay">
        {estimatedAmount && parseFloat(estimatedAmount).toFixed(2)}
        &nbsp;
        {currencyticker && currencyticker.toUpperCase()}
      </span>
    </div>
      <p className="amount-to-pay-in-usd">{planDetails.amount}</p>
  </div>
  );
}

export default CryptoPayment;
