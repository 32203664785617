import React, { useEffect } from "react";
import "./App.css";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import useFirstStep from "./hooks/useFirstStep";
import FirstStepComponent from "./components/firstStepComponent/FirstStepComponent";
import PaymentDataRender from "./components/paymentRender/PaymentDataRender";
import AllDone from "./components/alldone/AllDone";
import Failed from "./components/alldone/Failed";
import PaymentProcessing from "./components/paymentProcessing/PaymentProcessing";
import HeaderSiblingComponent from "./components/header/HeaderSibling";

const STRIPE_KEY =
  "pk_live_51MAv9FBo7RsUzVy5FG7lt40VWMoKl5sESkA2NNoDNhvrd1hEJslQbDayKttwHglz0jLAz5BJ1jzKq7xSuuPkwNf800by2wL89W";
const stripePromise = loadStripe(STRIPE_KEY);

function App() {
  const { actualState, setActualState, renderFirstStepProps, setShouldDisplayCircle } = useFirstStep();

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setShouldDisplayCircle(width > 421);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setShouldDisplayCircle]);

  const sibling = <HeaderSiblingComponent {...renderFirstStepProps.headerSiblingProps} />;

  const renderOnState = () => {
    switch (actualState) {
      case "firstStep":
        return (
          <div className="app-container">
            {sibling}
            <FirstStepComponent {...renderFirstStepProps} />;
          </div>
        )
      case "payment":
        return (
          <div className="app-container">
            {sibling}
            <PaymentDataRender {...renderFirstStepProps.paymentDataRenderProps} />
          </div>
        );
      case "allDone":
        return (
          <div className="app-container">
            {sibling}
            <AllDone
              paymentData={renderFirstStepProps.paymentData}
              paymentDataCard={renderFirstStepProps.paymentDataCard}
              // paymentIdMongo={renderFirstStepProps.paymentIdMongo}
            />
          </div>
        );
      case "failed":
        return (
          <div className="app-container">
            {sibling}
            <Failed />
          </div>
        );
      case "paymentProcessing":
        return (
          <div className="app-container">
            {sibling}
            <PaymentProcessing
              setActualState={setActualState}
              paymentData={renderFirstStepProps.paymentData}
            />
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <div className="container">{renderOnState()}</div>
    </Elements>
  );
}

export default App;