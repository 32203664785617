import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/legacy" element={<App planType="legacy" />} />
        <Route path="/pro" element={<App planType="pro" />} />
        <Route path="/corporate" element={<App planType="corporate" />} />
        <Route path="/" element={<App planType="corporate" />} />
        <Route path="/test" element={<App planType="test" />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
