import { useState, useEffect } from "react";
import validator from "validator";
import { fetchOrCreatePayment } from "../components/paymentForms/PaymentHandler";
import getPlanDetails from "../services/getPlanDetails";
import RenderErrorMessages from "../utils/ErrorMessages";

function useFirstStep() {
  const urlParams = new URLSearchParams(window.location.search);
  const introducerParam = urlParams.get("by");

  const [actualState, setActualState] = useState("firstStep");
  const [email, setEmail] = useState("");
  const [introducer, setIntroducer] = useState(introducerParam ?? "");
  const [isPayingInCrypto, setIsPayingInCrypto] = useState(null);
  const [isPayingSelected, setIsPayingSelected] = useState(false);
  const [isIntroducerValid, setIsIntroducerValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [shouldDisplayCircle, setShouldDisplayCircle] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [selectedCryptoData, setSelectedCryptoData] = useState([]);
  const [showEmailError, setShowEmailError] = useState(false);
  const [cashbackAmount, setCashbackAmount] = useState(null);
  const [selectedCryptoCurrency, setSelectedCryptoCurrency] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [paymentDataCard, setPaymentDataCard] = useState(null);
  const [payButtonClicked, setPayButtonClicked] = useState(false);
  const [estimatedAmount, setEstimatedAmount] = useState("");
  const [currencyticker, setCurrencyticker] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [cardDataIsComplete, setCardDataIsComplete] = useState(null);
  const [paymentIdMongo, setpaymentIdMongo] = useState("");
  const [inputTimeout, setInputTimeout] = useState(null);
  const [showIntroducerError, setShowIntroducerError] = useState(false);
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);

  const isFromUS = selectedCountry === "USA";

  const planDetails = getPlanDetails();

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setShouldDisplayCircle(width > 421);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/selected.json`);
        const data = await response.json();
        setSelectedCryptoData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [introducerParam]);

  useEffect(() => {
    return () => {
      if (inputTimeout) {
        clearTimeout(inputTimeout);
      }
    };
  }, [introducer, inputTimeout, email]);
  
  const fetchCashback = async (introducerCode) => {
    if (introducerCode.length !== 6) {
      setCashbackAmount(null);
      return;
    }

    try {
      const response = await fetch(
        `https://external-payment-backend.onrender.com/shortcode-cashback?referer=${introducerCode}`
      );
      const data = await response.json();
      if (data.cashback !== 0) {
        setCashbackAmount(data.cashback);
      } else {
        setCashbackAmount(null);
      }
    } catch (error) {
      console.error("Error fetching cashback:", error);
      setCashbackAmount(null);
    }
  };

  useEffect(() => {
    if (introducerParam) {
      fetchCashback(introducerParam);
    }
  }, [introducerParam]);
  
  const handleIntroducerChange = (event) => {
    const newIntroducer = event.target.value;
    setIntroducer(newIntroducer);
    setIsIntroducerValid(
      newIntroducer.length === 6 || newIntroducer.length === 0
    );
    setShowIntroducerError(false);

    if (newIntroducer.length === 6) {
      fetchCashback(newIntroducer);
    } else {
      setCashbackAmount(null);
    }

    if (newIntroducer.length !== 6) {
      if (inputTimeout) {
        clearTimeout(inputTimeout);
      }

      setInputTimeout(
        setTimeout(() => {
          setShowIntroducerError(true);
        }, 1000)
      );
    } else {
      setShowIntroducerError(false);
    }
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    const emailValidity = validator.isEmail(newEmail);
    setIsEmailValid(emailValidity);
    setShowEmailError(false);

    if (emailValidity && (selectedPaymentMethod || isPayingSelected)) {
      setSelectedPaymentMethod(null);
      setIsPayingSelected(false);
      setIsPayingInCrypto(null);
    }

    if (!emailValidity) {
      if (inputTimeout) {
        clearTimeout(inputTimeout);
      }

      setInputTimeout(
        setTimeout(() => {
          setShowEmailError(true);
        }, 1000)
      );
    } else {
      setShowEmailError(false);
    }
  };

  const handleClickPayCryptoSelected = async () => {
    try {
      setShowLoading(true);
      setPayButtonClicked(true);
      let response;

      if (selectedCryptoCurrency) {
        response = await fetchOrCreatePayment(
          planDetails,
          email,
          isFromUS,
          selectedCryptoCurrency,
          introducer,
          cashbackAmount
        );
      }

      if (response && response.pay_address && response.payment_id) {
        setPaymentData(response);
        setActualState("payment");
      } else {
        console.error(
          "La respuesta no contiene la dirección de pago:",
          response
        );
      }
    } catch (error) {
      console.error("Error durante el procesamiento del pago:", error);
    } finally {
      setShowLoading(false);
      setIsPayingInCrypto(true);
    }
  };

  const handleTogglePaymentMethod = (isCrypto) => {
    if (!isEmailValid || email.trim() === "") {
      setShowEmailError(true);
      return;
    }
    setIsPayingInCrypto(isCrypto);
    setSelectedPaymentMethod(isCrypto ? "crypto" : "card");
    setIsPayingSelected(true);
    setSelectedCountry("");
    if (cardDataIsComplete) {
      setCardDataIsComplete(false);
    }
  };

  const handleToggleErrorMessage = () => {
    if (!isEmailValid) {
      setShowEmailError(true);
    }
  };

  const renderErrorMessages = () => {
    return <RenderErrorMessages {...renderFirstStepProps.renderErrorMessagesProp}/>
  };

  const renderFirstStepProps = {
    paymentMethodButtonsProps: {
      handleTogglePaymentMethod,
      selectedPaymentMethod,
      isEmailValid,
      isPayingSelected,
      handleToggleErrorMessage,
    },
    renderErrorMessagesProp: {
      isIntroducerValid,
      showIntroducerError,
      showEmailError,
      email,
    },
    paymentDataRenderProps: {
      setActualState,
      paymentData,
      planDetails,
      estimatedAmount,
      currencyticker,
      cashbackAmount,
      selectedCryptoData,
    },
    cryptoPaymentProps: {
      planDetails,
      selectedCryptoData,
      setSelectedCryptoCurrency,
      estimatedAmount,
      setEstimatedAmount,
      currencyticker,
      setCurrencyticker,
    },
    cardPaymentFormProps: {
      email,
      introducer,
      isFromUS,
      isEmailValid,
      isIntroducerValid,
      setActualState,
      planDetails,
      setpaymentIdMongo,
      cashbackAmount,
      isPayingInCrypto,
      isPayingSelected,
      selectedCountry,
      setSelectedCountry,
      setPaymentDataCard,
      setPayButtonClicked,
      introducerParam,
    },
    headerSiblingProps: {
      payButtonClicked,
      shouldDisplayCircle,
      planDetails,
      email,
      handleEmailChange,
      isPlaceholderVisible,
      introducer,
      handleIntroducerChange,
      setIsPlaceholderVisible,
      renderErrorMessages,
      isPayingSelected,
    },
    showLoading,
    isPayingSelected,
    isPayingInCrypto,
    setSelectedCountry,
    invoiceUrl,
    isIntroducerValid,
    cashbackAmount,
    isEmailValid,
    introducerParam,
    planDetails,
    handleClickPayCryptoSelected,
    selectedCountry,
    selectedCryptoCurrency,
  };

  return { actualState, setActualState, renderFirstStepProps, shouldDisplayCircle, setShouldDisplayCircle };
}

export default useFirstStep;
