import React from "react";
import './FirstStepComponent.css'
import PaymentMethodButtons from "../paymentForms/PaymentMethodButtons";
import CountrySelect from "../select/CountrySelect";
import CryptoPayment from "../paymentForms/CryptoPayment";
import CardPaymentForm from "../paymentForms/CardPaymentForm";
import { ReactComponent as Cashback } from "../../assets/Cashback.svg";
import LoadingImage from "../loadingImage/LoadingImage";

const FirstStepComponent = ({
  showLoading,
  paymentMethodButtonsProps,
  isPayingSelected,
  isPayingInCrypto,
  setSelectedCountry,
  cryptoPaymentProps,
  cardPaymentFormProps,
  invoiceUrl,
  isIntroducerValid,
  cashbackAmount,
  isEmailValid,
  introducerParam,
  planDetails,
  handleClickPayCryptoSelected,
  selectedCountry,
  selectedCryptoCurrency
}) => {

  return (
    <div className="half-bottom-content-container">
      <div className="half-bottom-content-box">
        {showLoading ? (
          <LoadingImage className='loading-image'/>
        ) : (
          <>
            <PaymentMethodButtons {...paymentMethodButtonsProps} />
            {isPayingSelected && isPayingInCrypto && (
              <>
                <CountrySelect
                  setSelectedCountry={setSelectedCountry}
                />
              </>
            )}
            {isPayingSelected && (
              <>
                {isPayingInCrypto ? (
                  <CryptoPayment {...cryptoPaymentProps}/>
                ) : (
                  <>
                    <CardPaymentForm {...cardPaymentFormProps}/>
                  </>
                )}
              </>
            )}
            {invoiceUrl && (
              <p>
                Invoice Link:
                <a
                  href={invoiceUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {invoiceUrl}
                </a>
              </p>
            )}
            {isIntroducerValid && (
              <>
                {(introducerParam || cashbackAmount) && cashbackAmount && (isPayingInCrypto || !isPayingSelected) && (
                    <div className="your-cashback-box">
                      <div className="your-cashback-icon-text">
                        <Cashback className="your-cashback"></Cashback>
                        <span className="your-cashback-txt">
                          Your cashback:
                        </span>
                      </div>
                      <span className="cashback-box-amount">
                        {cashbackAmount &&
                          parseFloat(cashbackAmount).toFixed(2)}{" "}
                        USDC
                      </span>
                    </div>
                  )}
              </>
            )}
            {!isPayingSelected && (
              <button
                disabled={!isPayingSelected}
                className="pay-button"
              >
                <div className="pay-button-text-icon">
                  <span>Pay {planDetails.amount}</span>
                </div>
              </button>
            )}
            {isPayingInCrypto && (
              <button
                disabled={!( isEmailValid && isIntroducerValid && isPayingSelected && selectedCountry && selectedCryptoCurrency)}
                className="pay-button in-crypto-pay"
                onClick={handleClickPayCryptoSelected}
              >
                <div className="pay-button-text-icon">
                  <span>Pay {planDetails.amount}</span>
                </div>
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default FirstStepComponent;