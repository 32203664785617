import React from 'react';

import {ReactComponent as CryptoPay} from '../../assets/CryptoPay.svg';
import {ReactComponent as CardPay} from '../../assets/CardPay.svg';
import './PaymentMethodButtons.css'

const PaymentMethodButtons = (props) => {
  const { handleTogglePaymentMethod, selectedPaymentMethod, isEmailValid, handleToggleErrorMessage, isPayingSelected } = props;

  return (
    <div className='payment-method-box' onClick={() => {handleToggleErrorMessage()}}>
      <button
        id='payment-method-item'
        className={`payment-method-item ${selectedPaymentMethod === 'crypto' ? 'selected' : ''} ${isEmailValid === false ? 'disabled-button' : ''}`}
        onClick={() => handleTogglePaymentMethod(true)}
        disabled={!isEmailValid}
      >
        <CryptoPay className={`pay-img ${selectedPaymentMethod === 'crypto' || (!isPayingSelected) ? 'selected' : ''}`}></CryptoPay>
        <span className='pay-method-txt'>Crypto</span>
      </button>
      <button
        id='payment-method-item'
        className={`payment-method-item ${selectedPaymentMethod === 'card' ? 'selected' : ''} ${isEmailValid === false ? 'disabled-button' : ''}`}
        onClick={() => handleTogglePaymentMethod(false)}
        disabled={!isEmailValid}
      >
        <CardPay className={`pay-img ${selectedPaymentMethod === 'card' || (!isPayingSelected) ? 'selected' : ''}`}></CardPay>
        <span className='pay-method-txt'>Card</span>
      </button>
    </div>
  );
};

export default PaymentMethodButtons;